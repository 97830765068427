import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { ISearchResultsModel } from "@vmlyr/connekdfordshared/models/api/search-results-model";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { SearchType } from "./search-type";

const PAGE_SIZE = 100;

export async function listAudiencesPage(
  searchResultPage: number,
  searchType: SearchType,
): Promise<ISearchResultsModel> {
  const endpoint = ConfigurationHelper.GetAudiencesEndpoint(
    searchResultPage * PAGE_SIZE,
    PAGE_SIZE,
    searchType.toLowerCase(),
  );

  return await getWithToken<ISearchResultsModel>(endpoint);
}
