import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { EmptyCondition } from "../../../components/query-builder/models/empty-condition";
import type { GroupCondition } from "../../../components/query-builder/models/group-condition";
import { QueryBuilderModel } from "../../../components/query-builder/models/query-builder-model";
import type { QueryCondition } from "../../../models/query-builder/query-condition";
import { createSelectors } from "../../../zustand/selectors";
import { validatorHelper } from "../helpers/validatorHelper";

export interface Group {
  id: number;
  name: string;
  definition: QueryBuilderModel;
  validationError: string | null;
}

interface State {
  nudgeValue: number;
  groups: Group[];
  groupsDropTarget: Group["id"] | null;
  nextId: Group["id"];
  hasUnsavedChanges: boolean;
  showSaveGroupModal: number;
}

interface Actions {
  hasGroupChanged: (value: boolean) => void;
  nudgeState: () => void;

  addGroup: () => void;
  removeGroup: (id: Group["id"]) => void;
  resetGroups: () => void;
  overrideGroups: (newGroups: State["groups"]) => void;

  setGroupsDropTarget: (id: Group["id"]) => void;
  clearGroupsDropTarget: () => void;

  updateGroupName: (id: Group["id"], name: Group["name"]) => void;

  updateGroupDefinition: (id: Group["id"], newDefinition: QueryBuilderModel) => void;
  removeGroupDefinition: (deletedCondition: QueryCondition, currentDefinition: QueryBuilderModel) => void;

  openSaveGroupModal: (id: number) => void;
  closeSaveGroupModal: () => void;
}

export type GroupsState = State & { actions: Actions };

const createInitialGroups: () => Group[] = () => [
  {
    id: 0,
    name: "",
    validationError: null,
    definition: new QueryBuilderModel(new EmptyCondition(), "", [], "", "", null, ""),
  },
];

const useGroupsStoreBase = create(
  devtools<GroupsState>((set) => ({
    nudgeValue: 1,

    groupsDropTarget: null,

    nextId: 1,

    hasUnsavedChanges: false,

    groups: createInitialGroups(),

    showSaveGroupModal: -1,

    actions: {
      nudgeState: () => {
        set(() => ({ nudgeValue: Math.floor(Math.random() * 1000000) }));
      },

      addGroup: () => {
        set((state: State) => ({
          groups: [
            ...state.groups,
            {
              id: state.nextId,
              name: "",
              validationError: null,
              definition: new QueryBuilderModel(new EmptyCondition(), "", [], "", "", null, ""),
            },
          ],

          nextId: state.nextId + 1,
          hasUnsavedChanges: true,
        }));
      },

      removeGroup: (groupId: Group["id"]) => {
        set((state: State) => ({
          groups: state.groups.filter((dataItem) => dataItem.id !== groupId),
          hasUnsavedChanges: true,
        }));
      },

      resetGroups: () => {
        set(() => ({
          groups: createInitialGroups(),
          groupsDropTarget: null,
          nextId: 1,
          hasUnsavedChanges: false,
        }));
      },

      overrideGroups: (newGroups: State["groups"]) => {
        set(() => {
          const nextId = Math.max(...newGroups.map((g) => g.id)) + 1;
          return {
            groups: newGroups,
            nextId,
            hasUnsavedChanges: false,
          };
        });
      },

      setGroupsDropTarget: (groupId: Group["id"]) => {
        set(() => ({ groupsDropTarget: groupId }));
      },

      clearGroupsDropTarget: () => {
        set(() => ({ groupsDropTarget: null }));
      },

      updateGroupName: (groupId: Group["id"], name: Group["name"]) => {
        set((state: State) => ({
          groups: state.groups.map((group) => {
            if (group.id === groupId) {
              const validationError = validatorHelper(name);
              return { ...group, name, validationError };
            }

            return group;
          }),
          hasUnsavedChanges: true,
        }));
      },

      updateGroupDefinition: (groupId: Group["id"], newDefinition: Group["definition"]) => {
        set((state: State) => {
          const newGroups = state.groups.map((group) =>
            group.id === groupId
              ? {
                  ...group,
                  definition: newDefinition,
                }
              : group,
          );

          return {
            groups: newGroups,
            hasUnsavedChanges: true,
          };
        });
      },

      removeGroupDefinition: (deletedCondition: QueryCondition, currentDefinition: QueryBuilderModel) => {
        set((state: State) => {
          let newDefinition: QueryBuilderModel;

          if (!deletedCondition.getParent()) {
            newDefinition = QueryBuilderModel.fromExisting(currentDefinition, new EmptyCondition());
          } else {
            const parentCondition = deletedCondition.getParent() as GroupCondition;
            if (parentCondition.getLeftOperand() === deletedCondition) {
              parentCondition.setLeftOperand(null);
            } else if (parentCondition.getRightOperand() === deletedCondition) {
              parentCondition.setRightOperand(null);
            } else {
              throw new Error(
                "Critical error: deleted condition was neither the left nor the right operand of the node marked as its parent",
              );
            }

            newDefinition = QueryBuilderModel.fromExisting(currentDefinition);
          }

          const updatedGroups = state.groups.map((group: Group) =>
            group.definition === currentDefinition ? { ...group, definition: newDefinition } : group,
          );

          return {
            groups: updatedGroups,
            hasUnsavedChanges: true,
            nudgeValue: Math.random(),
          };
        });
      },

      openSaveGroupModal: (id: number) => {
        set(() => ({ showSaveGroupModal: id }));
      },

      closeSaveGroupModal: () => {
        set(() => ({ showSaveGroupModal: -1 }));
      },
      hasGroupChanged: (value: boolean) => {
        set(() => ({
          hasUnsavedChanges: value,
        }));
      },
    },
  })),
);

export const useGroupsStore = createSelectors(useGroupsStoreBase).use;

export const useGroupsActions = (): Actions => useGroupsStore.actions();

export const useGroupHasUnsavedChanges = (): GroupsState["hasUnsavedChanges"] => useGroupsStore.hasUnsavedChanges();

export const useGroupIsValid = (): boolean =>
  useGroupsStoreBase((state) => {
    const isValid = state.groups
      .map((group) => group.definition.rootCondition.getValidationState().isValid)
      .every(Boolean);

    return state.groups.length > 0 && isValid;
  });

export const useGroupsNudge: () => GroupsState["nudgeValue"] = useGroupsStore.nudgeValue;

export const useGroupsData: () => GroupsState["groups"] = useGroupsStore.groups;

export const useGroupsDropTarget: () => GroupsState["groupsDropTarget"] = useGroupsStore.groupsDropTarget;

export const useShowSaveGroupModal: () => GroupsState["showSaveGroupModal"] = useGroupsStore.showSaveGroupModal;

export const useGroup = (id: Group["id"]): Group => {
  const groups = useGroupsData();
  const result = groups.find((x) => x.id === id);

  if (result === undefined) {
    throw new Error("invalid group id");
  }

  return result;
};

export const readGroups: () => State["groups"] = () => useGroupsStoreBase.getState().groups;
