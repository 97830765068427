import type React from "react";
import { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import type { GridCallbackDetails, GridColDef, GridSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import type { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { CenteredProgress } from "@vmlyr/appserviceshared/dist/components/centered-progress";
import { useOnScreen } from "@vmlyr/appserviceshared/dist/hooks/useOnScreen";
import type { IProjectData } from "../../models/projectModel";

const initialTableState: GridInitialStatePro = {
  columns: {
    columnVisibilityModel: {
      created: false,
      effectiveDate: false,
    },
  },
  sorting: {
    sortModel: [{ field: "effectiveDate", sort: "desc" }],
  },
};

type HandleProjectsSelected = (selectedProjects: IProjectData[]) => void;

interface ProjectsTableProps {
  projectList: IProjectData[];
  selectable: boolean;
  allowPaging: boolean;
  onScroll: (isScrolledToBottom: boolean, ref: React.RefObject<HTMLDivElement>) => void;
  columnDefinition: GridColDef[];
  onProjectsSelected: HandleProjectsSelected;
  isLoadingMore: any;
  selectedProjects: any;
}

export function ProjectTable({
  projectList,
  allowPaging,
  onScroll,
  columnDefinition,
  onProjectsSelected,
  isLoadingMore,
  selectedProjects,
}: ProjectsTableProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isScrolledToBottom = useOnScreen(ref);
  const tableRef: any = useRef(null);

  useEffect(() => {
    onScroll(false, tableRef);
    setTimeout(() => {
      if (tableRef.current instanceof HTMLElement) {
        const tableWindow = tableRef.current.querySelector(".MuiDataGrid-virtualScroller");
        const handleScroll = () => {
          const lastRow = tableRef.current.querySelector(".MuiDataGrid-row--lastVisible");
          if (lastRow) {
            onScroll(true, lastRow);
            setTimeout(() => {
              onScroll(false, tableRef);
            }, 0);
          } else {
            onScroll(false, tableRef);
          }
        };
        tableWindow.addEventListener("scroll", handleScroll);
      }
    }, 0);
  }, []);

  useEffect(
    () => {
      onScroll(isScrolledToBottom, ref);
    },
    // eslint-disable-next-line
    [isScrolledToBottom],
  );

  const handleSelectionChanged = (e: GridSelectionModel) => {
    onProjectsSelected(e.map((f) => projectList.find((a: IProjectData) => a.id === f)!));
  };

  function handleSortModelChange(model: GridSortModel, details: GridCallbackDetails): void {
    if (model.length === 0) {
      details.api.setSortModel([{ field: "effectiveDate", sort: "desc" }]);
    }
    if (model.length > 0 && model.every((m) => m.field !== "effectiveDate")) {
      details.api.setSortModel([...model, { field: "effectiveDate", sort: "desc" }]);
    }
  }

  const getSelectedProjectsIDs = selectedProjects.map((item: any) => item.id);

  if (!projectList) {
    return <CenteredProgress />;
  }

  const pageHeight = `${window.innerHeight - 370}px`;
  return (
    <>
      <Box sx={{ height: pageHeight }}>
        <DataGrid
          checkboxSelection
          selectionModel={getSelectedProjectsIDs}
          onSelectionModelChange={handleSelectionChanged}
          onSortModelChange={handleSortModelChange}
          initialState={initialTableState}
          columns={columnDefinition}
          rows={projectList}
          pageSize={allowPaging ? 5 : projectList.length}
          rowsPerPageOptions={allowPaging ? [5] : [projectList.length]}
          disableSelectionOnClick
          hideFooter={!allowPaging}
          ref={tableRef}
        />
      </Box>
      {isLoadingMore && <CenteredProgress />}
    </>
  );
}

ProjectTable.defaultProps = {
  selectable: true,
  allowPaging: true,
  isLoadingMore: false,
  onScroll: () => {},
  reloadProjectTable: () => {},
};
