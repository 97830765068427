import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import type { ISearchResultsModel } from "@vmlyr/connekdfordshared/models/api/search-results-model";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { SearchModel } from "../../screens/audiences/components/search/components/search-filters/models/search-model";
import type { SearchType } from "./search-type";

export async function searchAudiences(
  searchResultPage: number,
  searchType: SearchType,
  searchModel: SearchModel,
): Promise<ISearchResultsModel> {
  let searchUrl = ConfigurationHelper.GetSearchAudiencesEndpoint();
  searchUrl = addQueryParams(
    searchUrl,
    `page=${searchResultPage}`,
    `text=${searchModel.text || ""}`,
    `searchType=${searchType.toLowerCase()}`,
  );

  for (const key of searchModel.filters.keys()) {
    searchUrl = addQueryParams(
      searchUrl,
      `${key}=${searchModel.filters
        .get(key)
        ?.map((f) => f.code)
        .join(",")}`,
    );
  }

  return await getWithToken<ISearchResultsModel>(searchUrl);
}
