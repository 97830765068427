import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import type { GroupConfig } from "@vmlyr/connekdfordshared/dist/config/groups";
import { configGroups } from "@vmlyr/connekdfordshared/dist/config/groups";
import { configPermissions } from "@vmlyr/connekdfordshared/dist/config/permissions";

function readEnvironmentVariable(
  environmentVariableName: string,
  environmentVariableDescription: string | null = null,
): string {
  const returnValue = process.env[environmentVariableName];
  if (!returnValue)
    throw new Error(
      `The environment variable ${environmentVariableName} was not found. Please ensure this is set as part of the build. ${environmentVariableDescription}`,
    );

  return returnValue;
}

class XDPConfigurationHelper {
  GetAuthRedirectUrl(): string {
    return readEnvironmentVariable("REACT_APP_AUTH_REDIRECT");
  }

  GetMaterialUILicense(): string {
    try {
      return readEnvironmentVariable("REACT_APP_MUI_LICENSE");
    } catch {
      console.warn("Expected to find `REACT_APP_MUI_LICENSE`, but got nothing. Continuing...");
      return "";
    }
  }

  GetKnowledgeHubLink(): string {
    return readEnvironmentVariable("REACT_APP_GET_KNOWLEDGE_HUB_URL");
  }

  GetNotificationsEndpoint(): string {
    return readEnvironmentVariable("REACT_APP_GET_NOTIFICATIONS_FUNCTION_URL");
  }

  SaveProfileEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Profiles/Save`;
  }

  GetAppClientID(): string {
    // @ts-expect-error
    const cypressRunning = window.Cypress;
    return cypressRunning
      ? readEnvironmentVariable("REACT_APP_CLIENT_ID_CYPRESS")
      : readEnvironmentVariable("REACT_APP_CLIENT_ID");
  }

  GetAppIssuer(): string {
    // @ts-expect-error
    const cypressRunning = window.Cypress;
    return cypressRunning
      ? readEnvironmentVariable("REACT_APP_ISSUER_CYPRESS")
      : readEnvironmentVariable("REACT_APP_ISSUER");
  }

  GetAudienceToolGroups(currentGroup?: string | null): GroupConfig {
    // @ts-expect-error
    const isUnderTest = Boolean(window.Cypress);
    const allConfigs = configGroups(isUnderTest);
    const defaultGroup = allConfigs[0];

    if (currentGroup) {
      return allConfigs.find((e: GroupConfig) => e.oktaGroupName === currentGroup) || defaultGroup;
    }

    return defaultGroup;
  }

  GetPermissions() {
    return configPermissions()[0];
  }

  GetFeatureToggles() {
    return {
      dollyFeature: {
        enableFeature: process.env.REACT_APP_ENABLE_DOLLY === "true",
        useTestGroups: process.env.REACT_APP_USE_DOLLY_TEST_GROUPS === "true",
      },
    };
  }

  GetRecaptchaApiUrl(): string {
    return readEnvironmentVariable("REACT_APP_RECAPTCHA_API_URL");
  }

  GetRecaptchaSiteKey(): string {
    return readEnvironmentVariable("REACT_APP_RECAPTCHA_SITE_KEY");
  }

  getConnekdApiEndpoint(): string {
    return readEnvironmentVariable("REACT_APP_API_URL");
  }

  GetChartEndpoint(audienceId: string, dimensionId: string): string {
    return addQueryParams(
      `${this.getConnekdApiEndpoint()}/Audiences/Chart`,
      `audienceId=${encodeURIComponent(audienceId)}`,
      `dimensionId=${encodeURIComponent(dimensionId)}`,
    );
  }

  GetCurrentUserEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users/Current`;
  }

  GetUpdateUserEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users/Update`;
  }

  GetDeleteUsersEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users/Delete`;
  }

  GetUserAttributesEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users/Attributes`;
  }

  StopExportEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Export/Stop`;
  }

  StopProjectExportEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Projects/Export/Stop`;
  }

  GetDimensionsEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Dimensions`;
  }

  GetAudiencesEndpoint(start: number | null = null, count: number | null = null, searchType = "all"): string {
    return addQueryParams(
      `${this.getConnekdApiEndpoint()}/Audiences`,
      `start=${start}`,
      `count=${count}`,
      `searchType=${searchType}`,
    );
  }

  CreateUserEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users/Create`;
  }

  GetUsersEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users`;
  }

  GetExportUsersEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Users/Export`;
  }

  CreateAudienceEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Create`;
  }

  GetCustomAudienceEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Custom`;
  }

  DeleteAudiencesEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Delete`;
  }

  DistributeAudienceUsers(friendlyId: string): string {
    return addQueryParams(`${this.getConnekdApiEndpoint()}/Audiences/Distribute`, `friendlyId=${friendlyId}`);
  }

  GetSingleAudienceOverviewEndpoint(id: string): string {
    return addQueryParams(`${this.getConnekdApiEndpoint()}/Audiences/Overview`, `friendlyId=${id}`);
  }

  GetSingleAudienceDefinitionEndpoint(id: string): string {
    return addQueryParams(`${this.getConnekdApiEndpoint()}/Audiences/Definition`, `friendlyId=${id}`);
  }

  CreateProfileEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Profiles/Create`;
  }

  UpdateProfileEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Profiles/Update`;
  }

  GetAllowedTaxonomyEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Taxonomy`;
  }

  GetSearchAudiencesEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Search`;
  }

  ExportAudienceEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Export`;
  }

  CheckExportAudienceEndpoint(exportId: string): string {
    return addQueryParams(`${this.getConnekdApiEndpoint()}/Audiences/Export`, "action=check", `exportId=${exportId}`);
  }

  CheckExportProjectEndpoint(exportId: string): string {
    return addQueryParams(`${this.getConnekdApiEndpoint()}/Projects/Export`, "action=check", `exportId=${exportId}`);
  }

  ArchiveAudienceEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Audiences/Archive`;
  }

  UnarchiveAudienceEndpoint(audienceId: string): string {
    return addQueryParams(`${this.getConnekdApiEndpoint()}/Audiences/Unarchive`, `audienceId=${audienceId}`);
  }

  ArchiveProjectEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Projects/Archive`;
  }

  UnarchiveProjectEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Projects/Unarchive`;
  }

  DeleteProjectEndpoint(): string {
    return `${this.getConnekdApiEndpoint()}/Projects/Delete`;
  }
}

const ConfigurationHelper = new XDPConfigurationHelper();

export default ConfigurationHelper;
