import "./audience-table.scss";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import type { GridEventListener, GridRowParams, GridSelectionModel } from "@mui/x-data-grid-pro";
import { DataGridPro as DataGrid, useGridApiRef } from "@mui/x-data-grid-pro";
import type { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { CenteredProgress } from "@vmlyr/appserviceshared/dist/components/centered-progress";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AudienceTableDefinition from "../../components/audience-table/audience-table-definition";
import { useAudienceListActions } from "../../stores/audiences-list";
import { handleSortModelChange } from "../table-common/handle-sort-model-change";

const arrayValuesToObjectKeys = (array: Array<string | number>): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  for (const value of array) result[`${value}`] = true;
  return result;
};

const initialTableState: GridInitialStatePro = {
  columns: {
    columnVisibilityModel: {
      pinned: false,
      sortableDate: false,
    },
  },
  sorting: {
    sortModel: [
      { field: "pinned", sort: "desc" },
      { field: "sortableDate", sort: "desc" },
    ],
  },
};

interface Props {
  audienceList: AudienceOverview[];
  pinnedAudiences: AudienceOverview[];
  onAudiencesSelected: (selectedAudiences: AudienceOverview[]) => void;
  isSearchResult: boolean;
  onScroll: GridEventListener<"rowsScrollEnd"> | undefined;
  resetPinned?: number;
  selectable?: boolean;
  isLoading?: boolean;
}

export function AudienceTable(props: Props): JSX.Element {
  const {
    audienceList,
    pinnedAudiences,
    onAudiencesSelected,
    isSearchResult,
    onScroll,
    resetPinned = 0,
    selectable = true,
    isLoading = false,
  } = props;

  const apiRef = useGridApiRef();

  const navigate = useNavigate();

  const navigateToAudience = (param: GridRowParams): void => {
    const clickedAudience = audienceList.find((a) => a.id === param.id);

    if (clickedAudience === undefined) {
      console.warn("registered row click event, but did not find a matching audience");
      return;
    }

    navigate(`/audiences/${clickedAudience.id}`);
  };

  const [tableRowData, setTableRowData] = useState<AudienceOverview[]>([]);
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);
  const [pinnedRows, setPinnedRows] = useState<AudienceOverview[]>([]);

  const { updatePinned } = useAudienceListActions();

  useEffect(() => {
    const audienceIds: string[] = audienceList.map((value) => value.id);
    const audienceIdObject = arrayValuesToObjectKeys(audienceIds);

    // inject any missing pinnedRows into audienceList
    for (const row of pinnedRows) {
      if (audienceIdObject[row.id] === undefined) {
        updatePinned([...pinnedAudiences, row]);
      }
    }

    recalculatePinned();
    const rowsWithPinnedUpdated = updatePinnedColumnInRowData(audienceList);
    setTableRowData(rowsWithPinnedUpdated);
  }, [audienceList]);

  useEffect(() => {
    recalculatePinned();
    setTableRowData((trd) => updatePinnedColumnInRowData(trd));
    const newPinned = pinnedAudiences.filter((pinnedAudience) => {
      return audienceList.some((audience) => pinnedAudience.id === audience.id);
    });
    updatePinned(newPinned);
  }, [selectedIds]);

  useEffect(() => {
    if (resetPinned > 0) {
      setPinnedRows([]);
      setSelectedIds([]);
    }
  }, [resetPinned]);

  const recalculatePinned = () => {
    const selectedIdsObject = arrayValuesToObjectKeys(selectedIds);
    const lPinnedRows = tableRowData.filter((row) => !!selectedIdsObject[row.id]);
    setPinnedRows(lPinnedRows);
    onAudiencesSelected(lPinnedRows);
  };

  const updatePinnedColumnInRowData = (rows: AudienceOverview[]) => {
    const selectedIdsObject = arrayValuesToObjectKeys(selectedIds);
    const updatedRows = rows.map((row) =>
      selectedIdsObject[row.id] ? { ...row, pinned: true } : { ...row, pinned: false },
    );

    return updatedRows.filter((item) => {
      const isInAudienceResults = audienceList.some((refItem) => item.id === refItem.id);
      return item.pinned || isInAudienceResults;
    });
  };

  const pageHeight: string = `${Math.max(window.innerHeight - 300, 300)}px`;

  if (!isLoading && tableRowData.length === 0 && pinnedAudiences.length === 0) {
    return (
      <Alert severity="info">
        {isSearchResult ? "The given search returned no results" : "You do not currently have any audiences defined"}
      </Alert>
    );
  }

  return (
    <>
      {isLoading && <CenteredProgress />}
      <Box id="audience-list" sx={{ height: pageHeight }}>
        <DataGrid
          apiRef={apiRef}
          initialState={initialTableState}
          columns={AudienceTableDefinition}
          rows={[...pinnedAudiences, ...tableRowData]}
          checkboxSelection={selectable}
          disableSelectionOnClick
          hideFooter
          onSelectionModelChange={setSelectedIds}
          onSortModelChange={handleSortModelChange}
          onRowClick={navigateToAudience}
          selectionModel={selectedIds}
          keepNonExistentRowsSelected
          onRowsScrollEnd={onScroll}
        />
      </Box>
    </>
  );
}
